<template>
  <div>
    <div v-if="!loading">
      <div class="history-list">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Action</th>
                <th class="text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in history" :key="index">
                <td>
                  {{ parseActivity(item) }}
                </td>
                <td>
                  <v-tooltip bottom color="primary">
                    <template #activator="{ on }">
                      <div v-on="on">
                        {{ fromNow(item) }}
                      </div>
                    </template>
                    <span>
                      {{ formatDate(item) }}
                    </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <div v-else class="text-center">
      <v-progress-circular indeterminate color="primary" size="40" width="5"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { eventRef } from '@/firebase/db';
import { getTimestamp } from '../../services/firebase';
import Moment from 'moment';
export default {
  props: ['parcel'],
  data: () => ({
    history: [],
    loading: true
  }),
  components: {},
  methods: {
    fromNow({ timestamp, createdAt }) {
      try {
        const d = timestamp || createdAt;
        return Moment(d.toDate()).fromNow();
      } catch {
        return '';
      }
    },
    formatDate({ timestamp, createdAt }) {
      const d = timestamp || createdAt;
      try {
        return Moment(d.toDate()).format('lll');
      } catch (error) {
        console.error(error);
        return d;
      }
    },
    getTimestamp(data) {
      const res = data.createdAt || data.timestamp;
      try {
        return res.toDate().getTime();
      } catch {
        return 0;
      }
    },
    async setHistory() {
      this.loading = true;
      let q = eventRef.where('parcel', '==', this.parcel.id).where('type', '!=', 'navigation');
      q = await q.get();
      const data = [];
      q.forEach(item => {
        data.push(item.data());
      });
      data.sort((a, b) => {
        try {
          return (
            (b.timestamp || b.createdAt).toDate().getTime() -
            (a.timestamp || a.createdAt).toDate().getTime()
          );
        } catch {
          return 0;
        }
      });
      this.history = data;
      this.loading = false;
    }
  },
  watch: {
    parcel: {
      immediate: true,
      handler: 'setHistory'
    }
  }
};
</script>

<style scoped>
.history-list {
  overflow-y: scroll;
  max-height: 60vh !important;
}
</style>
